/* main */
@import '_init.scss';

.main {
    .main-visual {
        position: relative;

        ul {
            li {
                a {
                    display: block;
                    height: 677px;
                }
            }
        }

        .swiper-btn-wrap {
            width: 1385px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -40px 0 0 -692px;

            .swiper-btn {
                opacity: .4;
                transition: opacity .4s;

                i {
                    display: block;
                    width: 80px;
                    height: 80px;
                    line-height: 1;
                    font-size: 80px;
                    text-align: center;
                    color: $font1;
                }

                &:hover {
                    opacity: 1;
                }
            }
        }

        .pagination {
            position: absolute;
            width: 100%;
            z-index: 10;

            .swiper-pagination-bullet {
                display: inline-block;
                vertical-align: top;
                width: 12px;
                height: 12px;
                background: transparent;
                border: 2px solid #bbbbbb;
                box-sizing: border-box;
                transition: all .3s;
            }

            .swiper-pagination-bullet-active {
                display: inline-block;
                vertical-align: top;
                width: 22px;
                height: 12px;
                border-radius: 12px;
                background: transparent;
                border: 2px solid #5f554b;
                box-sizing: border-box;
            }
        }
    }

    #content {
        padding: 0;

        .cate-area {
            margin: 0 -360px;
            background: $bg-gray;

            .inner {
                width: 1200px;
                margin: 0 auto;
                padding: 50px 0 225px;
                overflow: hidden;

                .about {
                    width: 440px;
                    height: 500px;
                    float: left;
                    padding: 40px;
                    box-sizing: border-box;
                    position: relative;
                    background: #fff url(../images/bg/bg-main-about.jpg) right bottom no-repeat;

                    .cate {
                        display: block;
                        font-size: 13px;
                        line-height: 1.5;
                        color: #b1977e;
                        letter-spacing: 3px;
                        font-weight: 700;
                        margin: 0 0 10px;
                    }

                    p {
                        &.sub {
                            font-size: 20px;
                            line-height: 28px;
                            font-weight: 300;
                            color: $font1;
                            margin: 0 0 5px;
                        }

                        font-size: 38px;
                        line-height: 45px;
                        color: $font1;

                        b {
                            font-weight: 900;
                        }
                    }

                    .more {
                        display: block;
                        width: 110px;
                        height: 35px;
                        line-height: 33px;
                        border-radius: 35px;
                        border: 1px solid #454545;
                        font-size: 13px;
                        font-weight: 700;
                        color: $font2;
                        text-align: center;
                        box-sizing: border-box;
                        position: absolute;
                        bottom: 40px;
                        left: 40px;
                        transition: all .5s;
                    }
                }

                .center-menu {
                    width: 380px;
                    float: left;

                    li {
                        display: table;
                        width: 100%;

                        &.faq {background: #5f554b url(../images/ico/ico-main-qna.png) center 45px no-repeat;}
                        &.media {background: #454545 url(../images/ico/ico-main-media.png) center 45px no-repeat;}

                        a {
                            display: table-cell;
                            vertical-align: middle;
                            padding: 60px 0 0;
                            height: 250px;
                            text-align: center;
                            box-sizing: border-box;

                            h3 {
                                font-size: 20px;
                                font-weight: 500;
                                color: #fff;
                                line-height: 1.5;
                            }

                            p {
                                font-family: 'notosans-dl';
                                color: #c7c7c7;
                                line-height: 30px;
                                margin: 0 0 15px;
                            }

                            .more {
                                display: inline-block;
                                vertical-align: top;
                                text-align: center;
                                overflow: hidden;
                                position: relative;

                                &:before {
                                    display: block;
                                    content: '';
                                    width: 100%;
                                    height: 1px;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    background: #fff;
                                    transition: all .5s;
                                }

                                em {
                                    display: block;
                                    float: left;
                                    font-size: 13px;
                                    color: #fff;
                                    line-height: 25px;
                                }

                                i {
                                    display: block;
                                    float: left;
                                    font-size: 13px;
                                    color: #fff;
                                    line-height: 25px;
                                    margin: 0 0 0 5px;
                                }
                            }
                        }
                    }
                }

                .program {
                    float: left;
                    height: 500px;
                    padding: 40px;
                    box-sizing: border-box;
                    background: #fff;
                    position: relative;

                    h3 {
                        font-size: 28px;
                        line-height: 1;
                        font-weight: 700;
                        color: $font1;
                        margin: 0 0 30px;
                    }

                    .img-box {
                        height: 250px;
                        margin: 0 0 15px;

                        img {
                            width: 100%;
                            height: 250px;
                        }
                    }

                    .txt-box {
                        p {
                            line-height: 22px;
                            font-family: 'notosans-dl';
                            color: #747474;

                            b {
                                display: block;
                                font-size: 18px;
                                font-weight: 700;
                                color: $font1;
                                margin: 0 0 7px;
                            }
                        }
                    }

                    .more {
                        display: block;
                        font-size: 13px;
                        line-height: 28px;
                        color: #666666;
                        position: absolute;
                        top: 40px;
                        right: 40px;
                    }
                }
            }
        }

        .wide-area {
            margin: -175px -300px 50px;
            padding: 77px 0;
            text-align: center;
            background: url(../images/bg/bg-main-wide.jpg) 0 0/cover no-repeat;

            .cate {
                display: block;
                font-size: 13px;
                line-height: 1;
                color: #cccccc;
                text-align: center;
                font-weight: 700;
                margin: 0 0 12px;
                letter-spacing: 3px;
            }

            p {
                font-size: 24px;
                font-weight: 300;
                color: #cccccc;
                line-height: 35px;
                text-align: center;
                margin: 0 0 45px;

                b {
                    display: block;
                    font-size: 40px;
                    line-height: 1;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .btn-wrap {
                display: inline-block;
                vertical-align: top;
                overflow: hidden;

                a {
                    display: block;
                    width: 150px;
                    height: 45px;
                    float: left;
                    line-height: 43px;
                    font-size: 13px;
                    font-weight: 700;
                    border: 1px solid #ddd;
                    box-sizing: border-box;
                    border-radius: 45px;
                    margin: 0 0 0 8px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &.yellow {
                        color: #ffb241;
                        border-color: #ffb241;
                    }

                    &.sky {
                        color: #24b5c2;
                        border-color: #24b5c2;
                    }
                }
            }
        }

        .item-area {
            margin: 0 0 95px;

            ul {
                overflow: hidden;

                li {
                    width: 390px;
                    float: left;
                    text-align: center;
                    margin: 0 0 0 15px;
                    border: 1px solid #ddd;
                    box-sizing: border-box;
                    position: relative;

                    &:before {
                        display: block;
                        content: '';
                        width: 100%;
                        height: 100%;
                        border: 1px solid transparent;
                        box-sizing: border-box;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: all .5s;
                    }

                    &:hover {
                        &:before {
                            border-color: $main;
                        }

                        .txt-box {
                            .more {
                                color: $main;

                                &:before {
                                    background: $main;
                                }
                            }
                        }
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        display: block;

                        &:before {
                            display: block;
                            content: '';
                            width: 100%;
                            height: 100%;
                        }

                        .img-box {
                            width: 100%;
                            height: 250px;
                            background: #ddd;

                            img {
                                width: 100%;
                                height: 250px;
                            }
                        }

                        .txt-box {
                            padding: 20px 0 30px;

                            h3 {
                                font-size: 25px;
                                font-weight: 700;
                                color: $font1;
                                line-height: 1;
                                margin: 0 0 7px;
                            }

                            p {
                                line-height: 20px;
                                font-weight: 300;
                                color: #747474;
                                margin: 0 0 20px;
                            }

                            .more {
                                display: inline-block;
                                vertical-align: top;
                                font-size: 13px;
                                color: #5f554b;
                                line-height: 25px;
                                position: relative;
                                transition: all .5s;

                                &:before {
                                    display: block;
                                    content: '';
                                    width: 100%;
                                    height: 1px;
                                    background: #5f554b;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    transition: all .5s;
                                }
                            }
                        }
                    }
                }
            }
        }

        .review-area {
            margin: 0 0 80px;

            .tit-area {
                margin: 0 0 32px;

                p {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 300;
                    color: $font1;
                    text-align: center;
                }

                h3 {
                    font-size: 38px;
                    line-height: 1.2;
                    font-weight: 700;
                    color: $font1;
                    text-align: center;
                }
            }

            ul {
                overflow: hidden;

                li {
                    width: 280px;
                    float: left;
                    margin: 0 0 0 26px;
                    border: 1px solid $border2;
                    box-sizing: border-box;
                    position: relative;

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        display: block;
                        position: relative;

                        &:hover {
                            &:before {
                                border-width: 3px;
                            }

                            &:after {
                                opacity: .8;
                                transform: translateY(0);
                            }

                            i {
                                transform: translateY(0) rotate(0);
                                opacity: 1;
                            }
                        }

                        &:before {
                            display: block;
                            content: '';
                            width: 100%;
                            height: 100%;
                            border: 0 solid #00b7ca;
                            box-sizing: border-box;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 20;
                            transition: all .5s;
                        }

                        &:after {
                            display: block;
                            content: '';
                            width: 100%;
                            height: 100%;
                            background: #fff;
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 10;
                            transition: opacity .5s;
                            transform: translateY(100%);
                        }

                        .img-box {
                            width: 100%;
                            height: 196px;
                            background: $bg-gray;

                            img {
                                width: 100%;
                                height: 196px;
                            }
                        }

                        .txt-box {
                            padding: 20px;
                            background: #fff;

                            p {
                                margin: 0 0 25px;

                                b {
                                    display: block;
                                    font-size: 18px;
                                    line-height: 26px;
                                    color: $font1;
                                    margin: 0 0 7px;
                                    @include ellipsis;
                                }

                                span {
                                    display: block;
                                    font-family: 'notosans-dl';
                                    color: $font2;
                                    line-height: 22px;
                                    height: 44px;
                                }
                            }

                            .date {
                                font-size: 13px;
                                line-height: 1;
                                color: #999999;
                            }
                        }

                        i {
                            display: block;
                            width: 46px;
                            height: 46px;
                            line-height: 46px;
                            font-size: 46px;
                            color: #00b7ca;
                            text-align: center;
                            margin: -23px 0 0 -23px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            z-index: 30;
                            opacity: 0;
                            transform: translateY(30px) rotate(180deg);
                            transition: all .5s;
                        }
                    }
                }
            }
        }

        .sns-area {    
            margin: 0 -360px;
            padding: 50px;
            background: $bg-gray;
            box-sizing: border-box;

            .tit-area {
                text-align: center;
                margin: 0 0 30px;

                h3 {
                    font-size: 38px;
                    font-weight: 700;
                    color: $font1;
                    line-height: 1;
                    margin: 0 0 15px;
                }

                .info {
                    display: inline-block;
                    vertical-align: top;
                    overflow: hidden;

                    span {
                        display: block;
                        font-size: 15px;
                        color: #333333;
                        line-height: 25px;
                        float: left;
                        margin: 0 20px 0 0;
                        font-family: 'nanumsquare';
                    }

                    .more {
                        display: block;
                        width: 68px;
                        height: 25px;
                        line-height: 23px;
                        text-align: center;
                        color: #5f554b;
                        border: 1px solid #5f554b;
                        border-radius: 25px;
                        box-sizing: border-box;
                        float: left;
                    }
                }
            }

            .sns-slide {
                width: 1200px;
                margin: 0 auto;
                position: relative;

                ul {
                    li {
                        a {
                            display: block;

                            img {
                                width: 185px;
                                height: 185px;
                            }
                        }
                    }
                }

                .swiper-btn {
                    display: block;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    top: 50%;
                    margin: -25px 0 0;

                    i {
                        display: block;
                        font-size: 50px;
                        line-height: 50px;
                        color: #999999;
                        text-align: center;
                    }

                    &.prev {left: -50px;}
                    &.next {right: -50px;}
                }
            }
        }
    }
}

.sub {
    &.board .sub-top-bnr {background: url(../images/bg/bg-sub-top02.jpg) center/cover no-repeat;}
    &.intro .sub-top-bnr {background: url(../images/bg/bg-sub-top01.jpg) center/cover no-repeat;}
    &.item .sub-top-bnr {background: url(../images/bg/bg-sub-top02.jpg) center/cover no-repeat;}
    &.media .sub-top-bnr {background: url(../images/bg/bg-sub-top06.jpg) center/cover no-repeat;}
    &.program .sub-top-bnr {background: url(../images/bg/bg-sub-top03.jpg) center/cover no-repeat;}
    &.review .sub-top-bnr {background: url(../images/bg/bg-sub-top05.jpg) center/cover no-repeat;}
    &.qna .sub-top-bnr {background: url(../images/bg/bg-sub-top04.jpg) center/cover no-repeat;}

    .sub-top-bnr {
        display: table;
        width: 100%;
        background: $main;

        .inner {
            display: table-cell;
            vertical-align: middle;
            height: 300px;

            span {
                display: block;
                text-align: center;
                font-size: 20px;
                line-height: 1;
                font-weight: 700;
                color: #fff;
                margin: 0 0 17px;
            }

            p {
                text-align: center;
                font-size: 38px;
                line-height: 45px;
                color: #fff;
                font-weight: 300;

                b {
                    font-weight: 700;
                }
            }
        }
    }

    .sub-nav {
        text-align: center;
        border-bottom: 1px solid #ddd;

        ul {
            display: inline-block;
            vertical-align: top;
            overflow: hidden;

            li {
                display: table;
                width: 180px;
                float: left;

                &.on {
                    a {
                        color: $main;
                        font-weight: 700;

                        &:before {
                            transform: scaleX(1);
                        }
                    }
                }

                a {
                    display: table-cell;
                    vertical-align: middle;
                    height: 46px;
                    font-size: 14px;
                    line-height: 25px;
                    color: #999999;
                    position: relative;

                    &:before {
                        display: block;
                        content: '';
                        width: 100%;
                        height: 3px;
                        background: $main;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transform: scaleX(0);
                        transition: all .5s;
                    }
                }
            }
        }
    }

    .sub-header {
        margin: 0 0 80px;
        
        h1 {
            font-size: 40px;
            color: $font1;
            font-weight: 700;
            line-height: 1;
            text-align: center;
        }
    }

    .board-top-area {
        overflow: hidden;
        margin: 0 0 20px;

        p {
            float: left;
            font-size: 13px;
            color: $font2;
            line-height: 40px;
            font-family: 'nanumsquare';
        }

        .search-area {
            float: right;

            select {
                display: block;
                width: 140px;
                height: 40px;
                border: 1px solid #ddd;
                box-sizing: border-box;
                float: left;
                border-radius: 40px;
                margin: 0 5px 0 0;
            }

            .search {
                float: right;
                position: relative;
                border: 1px solid #ddd;
                border-radius: 40px;

                .form {
                    display: block;
                    width: 300px;
                    height: 40px;
                    border: 0;
                    border-radius: 40px;
                    padding: 0 40px 0 0;
                    box-sizing: border-box;
                }

                .btn-search {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;

                    i {
                        display: block;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        font-size: 20px;
                        color: $main;
                    }
                }
            }
        }
    }

    .board-table {
        width: 100%;
        border-top: 2px solid #aaaaaa;

        thead {
            tr {
                th {
                    font-size: 15px;
                    font-weight: 700;
                    color: $font1;
                    height: 40px;
                    border-bottom: 1px solid #ddd;
                }
            }
        }

        tbody {
            tr {
                td {
                    height: 60px;
                    text-align: center;
                    border-bottom: 1px solid #ddd;
                    overflow: hidden;

                    a {
                        display: block;
                        float: left;

                        i {
                            display: block;
                            float: left;
                            font-size: 15px;
                            color: $font2;
                            margin: 0 15px 0 0;
                        }

                        p {
                            float: left;
                            max-width: 500px;
                            font-size: 15px;
                            line-height: 1;
                            @include ellipsis;
                        }

                        .re {
                            display: block;
                            float: left;
                            font-size: 15px;
                            color: $main;
                            margin: 0 0 0 13px;
                        }
                    }

                    &.reply {
                        padding-left: 40px;
                    }
                }
            }
        }
    }

    &.item {
        #content{
            .item-list {
                overflow: hidden;
                margin: 0 -13px -60px 0;

                li {
                    width: 290px;
                    float: left;
                    margin: 0 13px 60px 0;
                    border: 1px solid #ddd;
                    box-sizing: border-box;

                    a {
                        display: block;

                        .img-box {
                            height: 242px;
                            background: $bg-gray;

                            img {
                                width: 100%;
                                height: 242px;
                            }
                        }

                        .txt-box {
                            padding: 18px;

                            p {
                                color: #747474;
                                line-height: 22px;
                                height: 44px;
                                overflow: hidden;
                                margin: 0 0 22px;

                                &.tit {
                                    font-size: 20px;
                                    font-weight: 700;
                                    color: $font1;
                                    line-height: 26px;
                                    height: 52px;
                                    margin: 0 0 7px;
                                }
                            }

                            span {
                                display: block;
                                font-size: 13px;
                                line-height: 28px;

                                b {
                                    font-size: 28px;
                                    line-height: 1;
                                    color: $main;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }

            .item-info-area {
                padding: 0 0 80px;
                overflow: hidden;
                border-bottom: 1px solid #ddd;

                .visual {
                    width: 585px;
                    float: left;

                    .big {
                        width: 100%;
                        height: 488px;
                        background: $bg-gray;
                        margin: 0 0 7px;

                        img {
                            width: 100%;
                            height: 488px;                            
                        }
                    }

                    .thumb-list {
                        overflow: hidden;

                        li {
                            width: 140px;
                            height: 117px;
                            float: left;
                            margin: 0 0 0 7px;
                            background: $bg-gray;
                            border: 1px solid #ddd;
                            box-sizing: border-box;

                            &:first-child {
                                margin-left: 0;
                            }

                            img {
                                width: 100%;
                                height: 117px;
                            }
                        }
                    }
                }

                .info {
                    float: right;

                    .item-table {
                        thead {
                            tr {
                                th {
                                    padding: 0 0 17px;
                                    text-align: left;
                                    border-bottom: 2px solid $border1;

                                    p {
                                        line-height: 22px;

                                        b {
                                            display: block;
                                            font-size: 25px;
                                            font-weight: 700;
                                            color: $font1;
                                            line-height: 1;
                                            margin: 0 0 16px;
                                        }
                                    }
                                }
                            }
                        }

                        tfoot {
                            tr {
                                th {
                                    height: 80px;
                                    font-size: 15px;
                                    font-weight: 700;
                                    color: $font1;
                                    border-top: 1px solid #ddd;
                                }

                                td {
                                    height: 80px;
                                    font-size: 13px;
                                    text-align: right;
                                    border-top: 1px solid #ddd;

                                    b {
                                        font-size: 28px;
                                        color: $main;
                                        font-weight: 700;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                &.str-line {
                                    th, td {
                                        padding-top: 20px;
                                        border-top: 1px solid #ddd;
                                    }
                                }

                                &.end-line {
                                    th, td {
                                        padding-bottom: 20px;
                                    }
                                }

                                &.selected {
                                    td {
                                        padding: 20px 0;
                                        border-top: 1px solid #ddd;
                                    }
                                }

                                th {
                                    font-weight: 700;
                                    text-align: left;
                                    color: $font1;
                                    padding: 5px 0;
                                }

                                td {
                                    padding: 5px 0;

                                    select {
                                        display: block;
                                        width: 286px;
                                        height: 25px;
                                        border: 1px solid #c3c3c3;
                                        font-size: 12px;
                                        font-weight: 300;
                                        text-indent: 0;
                                    }

                                    ul {
                                        li {
                                            overflow: hidden;
                                            padding: 10px 20px;
                                            margin: 10px 0 0;
                                            background: $bg-gray;

                                            &:first-child {
                                                margin-top: 0;
                                            }

                                            p {
                                                width: 310px;
                                                padding: 0 20px 0 0;
                                                box-sizing: border-box;
                                                float: left;
                                                font-weight: 300;
                                                color: $font1;
                                            }

                                            .num-box {
                                                width: 88px;
                                                height: 20px;
                                                border: 1px solid #ddd;
                                                box-sizing: border-box;
                                                float: left;
                                                margin: 0 20px 0 0;

                                                .form {
                                                    display: block;
                                                    width: 48px;
                                                    height: 18px;
                                                    float: left;
                                                    border:0;
                                                    background: #fff;
                                                    padding: 0 0 0 10px;
                                                    text-align: left;
                                                    text-indent: 0;
                                                }

                                                button {
                                                    display: block;
                                                    width: 18px;
                                                    height: 18px;
                                                    float: left;
                                                    background: #fff;
                                                    border-left: 1px solid #ddd;

                                                    i {
                                                        display: block;
                                                        text-align: center;
                                                        line-height: 18px;
                                                        font-size: 13px;
                                                        color: $font1;                                                        
                                                    }
                                                }
                                            }

                                            .price {
                                                display: block;
                                                float: left;
                                                font-weight: 300;
                                                font-family: 'nanumsquare';

                                                b {
                                                    font-size: 20px;
                                                    font-weight: 700;
                                                    color: $font1;
                                                }
                                            }

                                            .del {
                                                display: block;
                                                float: left;
                                                width: 20px;
                                                height: 20px;
                                                margin: 0 0 0 13px;

                                                i {
                                                    display: block;
                                                    line-height: 20px;
                                                    font-size: 18px;
                                                    color: $font3;
                                                    text-align: center;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .item-detail {
                padding: 40px 0;

                img {
                    max-width: 100%;
                }
            }

            .btn-wrap {
                border-top: 1px solid $border1;
                padding: 10px 0 0;

                .btn40 {
                    width: 64px;
                    margin: 0 0 0 5px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &.list {
                        width: 100px;
                    }
                }
            }
        }
    }

    &.program {
        #content {
            padding: 73px 100px 120px;

            .bg-box {
                padding: 20px 30px 30px;
                background: #f4fcfd;

                dl {
                    overflow: hidden;
                    margin: -27px 0 0;

                    dt {
                        width: 205px;
                        float: left;
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 700;
                        color: $font1;
                        margin: 27px 0 0;
                    }

                    dd {
                        float: left;
                        margin: 27px 0 0;

                        ol {
                            li {
                                font-size: 18px;
                                line-height: 28px;
                            }
                        }

                        ul {
                            li {
                                font-size: 15px;
                                line-height: 28px;
                            }
                        }

                        p {
                            margin-bottom: 0;
                            margin: 6px 0 0;

                            &:first-child {
                                margin-top: 0;
                            }

                            b {
                                display: block;
                                font-size: 15px;
                                font-weight: 700;
                                color: #454545;
                            }
                        }

                        span {
                            display: block;
                            font-size: 13px;
                            line-height: 23px;
                            color: #888888;
                        }

                        img {
                            margin: 13px 0 0;
                        }
                    }
                }
            }

            .tab-tit-area {
                ul {
                    overflow: hidden;

                    li {
                        display: table;
                        width: 250px;
                        float: left;

                        &.on {
                            a {
                                background: #fff;
                                color: $font1;
                                font-weight: 700;
                                border: 1px solid $border2;
                                border-radius: 4px 4px 0 0;
                                border-bottom: 0;
                            }
                        }

                        a {
                            display: table-cell;
                            vertical-align: middle;
                            font-size: 16px;
                            line-height: 26px;
                            height: 60px;
                            background: #f5f5f5;
                            color: #999999;
                            text-align: center;
                        }
                    }
                }
            }

            .program-info {
                margin: 0 0 50px;

                h3 {
                    font-size: 30px;
                    line-height: 1;
                    font-weight: 300;
                    color: $font1;
                    margin: 0 0 13px;

                    b {
                        font-weight: 700;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 28px;
                    margin: 0 0 14px;
                }
            }

            .sec {
                padding: 52px 0 0;

                h1 {
                    font-size: 30px;
                    font-weight: 700;
                    color: $main;
                    line-height: 1;
                    font-family: 'nanumsquare';
                    margin: 0 0 12px;
                }

                p {
                    font-size: 16px;
                    line-height: 28px;
                    margin: 0 0 24px;
                }

                img {
                    margin: 0 0 24px;
                }

                .bg-box {
                    margin: 34px 0 0;

                    dl {
                        dt {
                            width: 82px;
                        }
                    }
                }

                .class-info {
                    position: relative;
                    margin: 80px 0 0;
                    overflow: hidden;

                    .btn-wrap {
                        position: absolute;
                        top: 0;
                        right: 0;

                        .btn-apply {
                            display: block;
                            width: 300px;
                            height: 55px;
                            border-radius: 4px;
                            background: $main;
                            text-align: center;
                            font-size: 17px;
                            color: #fff;
                            line-height: 55px;
                            font-weight: 700;

                            i {
                                display: inline-block;
                                vertical-align: middle;
                                margin: 0 6px 0 0;
                            }
                        }                    
                    }

                    .info-tit {
                        float: left;
                        min-height: 60px;
                        margin: 0 0 20px;

                        h4 {
                            font-size: 25px;
                            line-height: 28px;
                            color: $font1;
                            font-weight: 300;
                            font-family: 'nanumsquare';
                            padding: 0 0 0 26px;
                            position: relative;

                            &:before {
                                display: block;
                                content: '';
                                width: 10px;
                                height: 20px;
                                background: $main;
                                position: absolute;
                                top: 4px;
                                left: 0;
                            }

                            b {
                                font-weight: 700;
                            }
                        }

                        p {
                            font-size: 15px;
                            line-height: 28px;
                            margin: 5px 0 0;
                        }
                    }

                    table {
                        width: 100%;
                        border-top: 2px solid #aaaaaa;
                        margin: 30px 0 0;

                        &:first-child {
                            margin-top: 0;
                        }

                        thead {
                            tr {
                                th {
                                    height: 56px;
                                    background: $bg-gray;
                                    font-size: 15px;
                                    font-weight: 700;
                                    color: $font1;
                                    border-bottom: 1px solid #ddd;
                                    border-left: 1px solid #ddd;
                                    text-align: center;

                                    &:first-child {
                                        border-left: 0;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                th {
                                    padding:  13px 0;
                                    background: $bg-gray;
                                    border-bottom: 1px solid #ddd;
                                    text-align: center;
                                    color: $font1;
                                    font-family: 'nanumsquare';
                                }

                                td {
                                    padding: 13px 30px;
                                    border-left: 1px solid #ddd;
                                    border-bottom: 1px solid #ddd;

                                    dl {
                                        dt {
                                            font-size: 15px;
                                            line-height: 28px;
                                            color: $font1;
                                            font-weight: 700;
                                        }

                                        dd {
                                            font-size: 15px;
                                            line-height: 28px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .sec01 {}
            .sec02 {}
            .sec03 {
                .bg-box {
                    dl {
                        dt {
                            width: 200px;
                        }

                        dd {
                            width: 730px;
                        }
                    }
                }
            }
            .sec04 {
                p {
                    margin-bottom: 0;
                }
                
                .class-info {
                    margin-top: 50px;
                }
            }
        }
    }

    &.apply {
        #content {
            .info-box {
                border: 1px solid #dedede;
                background: #f8f8f8;
                padding: 40px;
                margin: 0 0 40px;
            }

            .apply-cont {
                text-align: center;

                .tit-area {
                    overflow: hidden;
                    padding: 0 0 13px;
                    margin: 0 0 14px;

                    h3 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #303030;
                        float: left;
                    }

                    p {
                        float: right;
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: 300;

                        em {
                            color: #ff0000;
                        }
                    }
                }

                table {
                    width: 100%;
                    border-top: 2px solid #434343;
                    margin: 0 0 60px;

                    tbody {
                        tr {
                            th {
                                height: 70px;
                                background: $bg-gray;
                                font-size: 15px;
                                color: $font1;
                                font-weight: 700;
                                padding: 10px 0 10px 20px;
                                text-align: left;
                                box-sizing: border-box;
                                border-bottom: 1px solid #cdcdcd;

                                &.ess {
                                    &:before {
                                        display: inline-block;
                                        content: '*';
                                        vertical-align: top;
                                        color: #f00;
                                    }
                                }

                                label {
                                    display: inline-block;
                                    vertical-align: top;
                                }
                            }

                            td {
                                padding: 10px 20px;
                                font-family: 'nanumsquare';
                                box-sizing: border-box;
                                color: $font1;
                                text-align: left;
                                border-bottom: 1px solid #cdcdcd;
                                border-left: 1px solid #cdcdcd;

                                select {
                                    width: 100%;
                                }

                                .phone {
                                    overflow: hidden;

                                    .form {
                                        width: 260px;
                                        float: left;
                                    }

                                    span {
                                        display: block;
                                        float: left;
                                        width: 20px;
                                        text-align: center;
                                        font-size: 15px;
                                        line-height: 50px;
                                    }
                                }

                                .mail {
                                    overflow: hidden;

                                    .form {
                                        width: 395px;
                                        float: left;
                                    }

                                    span {
                                        display: block;
                                        float: left;
                                        width: 30px;
                                        text-align: center;
                                        font-size: 15px;
                                        line-height: 50px;
                                    }
                                }
                            }
                        }
                    }
                }

                .agree {
                    display: inline-block;
                    vertical-align: top;
                    overflow: hidden;
                    margin: 0 0 40px;
                    padding: 0 0 0 30px;
                    position: relative;

                    &:before {
                        display: block;
                        content: '';
                        width: 24px;
                        height: 24px;
                        border: 1px solid #ddd;
                        background: #fff;
                        box-sizing: border-box;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .chk {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 10;
                        width: 24px;
                        height: 24px;
                        opacity: 0;
                        cursor: pointer;
                    }

                    .chk:checked ~ .chk-box {
                        &:before {
                            width: 10px;
                            height: 5px;
                            border-color: $main;
                            transition: width .2s .17s, height .2s, border-color .2s;
                        }
                    }

                    .chk-box {
                        display: block;
                        float: left;
                        line-height: 24px;

                        &:before {
                            display: block;
                            content: '';
                            width: 0;
                            height: 0;
                            border-top: 0px;
                            border-left: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                            border-right: 0px;
                            transform: rotate(-45deg);
                            position: absolute;
                            top: 5px;
                            left: 6px;
                        }
                    }

                    .more {
                        display: block;
                        float: left;
                        width: 78px;
                        text-align: center;
                        color: #fff;
                        line-height: 24px;
                        margin: 0 0 0 20px;
                        background: #8a8a8a;
                    }
                }

                .btn-wrap {
                    .btn60 {
                        width: 350px;
                    }
                }
            }

            .complete {
                text-align: center;

                p {
                    line-height: 25px;
                    text-align: center;
                    margin: 0 0 34px;

                    b {
                        display: block;
                        font-size: 35px;
                        font-weight: 700;
                        color: $font1;
                        line-height: 1;
                    }

                    em {
                        color: $main;
                    }
                }

                .result-box {
                    display: inline-block;
                    vertical-align: top;
                    width: 640px;
                    border: 2px solid #ddd;
                    padding: 40px 0;
                    margin: 0 0 80px;

                    table {
                        width: 325px;
                        margin: 0 auto;

                        tbody {
                            tr {
                                th {
                                    color: #303030;
                                    height: 40px;
                                    text-align: left;
                                    font-family: 'nanumsquare';
                                }

                                td {
                                    font-weight: 700;
                                    color: $font1;
                                    height: 40px;
                                    font-family: 'nanumsquare';
                                    text-align: left;
                                }
                            }
                        }
                    }
                }

                .btn-wrap {
                    .btn60 {
                        width: 350px;
                    }
                }
            }
        }
    }

    &.intro {
        #content {
            .about {
                position: relative;
                margin: 0 -360px 80px;
                padding: 0 0 73px;

                &:before {
                    display: block;
                    content: '';
                    width: 100%;
                    height: 615px;
                    background: $bg-gray;
                    position: absolute;
                    top: 168px;
                    left: 0;
                }
                
                .inner {
                    width: 1200px;
                    margin: 0 auto;
                    position: relative;
                    z-index: 10;

                    img {
                        margin: 0 0 60px;
                    }

                    h3 {
                        font-size: 35px;
                        line-height: 1;
                        color: $font1;
                        text-align: center;
                        margin: 0 0 36px;

                        b {
                            font-weight: 700;
                        }
                    }

                    p {
                        font-size: 15px;
                        color: $font2;
                        line-height: 28px;
                        text-align: center;
                        margin: 0 0 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        em {
                            font-size: 18px;
                            color: $font1;
                            text-align: center;
                        }
                    }
                }
            }

            .feature {
                ol {
                    li {
                        @include clear;

                        &.list01 {margin: 0 0 45px;}
                        &.list02 {
                            margin: 0 0 -50px;

                            .img-box {
                                &:before {
                                    display: block;
                                    content: '';
                                    width: 420px;
                                    height: 275px;
                                    background: #e5f4f5;
                                    position: absolute;
                                    bottom: -35px;
                                    right: -35px;
                                }
                            }
                        }
                        &.list03 {
                            margin: 0 0 80px;

                            .txt-box {
                                padding-top: 90px;
                            }
                        }
                        &.list04 {margin: 0 0 62px;}
                        &.list05 {
                            margin: 0 0 120px;

                            .img-box {
                                &:before {
                                    display: block;
                                    content: '';
                                    width: 960px;
                                    height: 373px;
                                    background: $bg-gray;
                                    position: absolute;
                                    bottom: -60px;
                                    right: -670px;
                                }

                            }
                        }

                        &.list06 {
                            margin: 0 0 60px;

                            .txt-box {
                                ul {
                                    width: 445px;
                                    background: $bg-gray;
                                }
                            }
                        }

                        &:nth-child(even) {
                            .img-box {
                                float: right;
                            }

                            .txt-box {
                                float: left;
                            }
                        }

                        &.on {
                            .img-box {
                                &:before {
                                    transform: scale(1);
                                    opacity: 1;
                                }

                                .inner {
                                    &:before {transform: scaleY(0);}
                                    &:after {transform: scaleY(0);}
                                }
                            }

                            .txt-box {
                                .num {
                                    em {
                                        opacity: 1;
                                        transform: translateY(0);
                                    }
                                }

                                h4 {
                                    transform: translateY(0);
                                    opacity: 1;
                                }

                                p {
                                    transform: translateY(0);
                                    opacity: 1;
                                }

                                ul {
                                    opacity: 1;
                                }
                            }
                        }

                        .img-box {
                            float: left;
                            position: relative;
                            z-index: 10;

                            &:before {
                                transform: scale(0);
                                opacity: 0;
                                transition: all .5s;
                            }
                                
                            .inner {
                                position: relative;

                                &:before {
                                    display: block;
                                    content: '';
                                    width: 100%;
                                    height: 50%;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 20;
                                    background: #fff;
                                    transform-origin: top;
                                    transition: all .5s;
                                }

                                &:after {
                                    display: block;
                                    content: '';
                                    width: 100%;
                                    height: 50%;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    z-index: 20;
                                    background: #fff;
                                    transform-origin: bottom;
                                    transition: all .5s;
                                }

                                img {
                                    position: relative;
                                    z-index: 10;
                                }
                            }
                        }

                        .txt-box {
                            float: left;
                            padding: 0 0 0 50px;
                            position: relative;
                            z-index: 10;

                            .num {
                                display: block;
                                height: 70px;
                                overflow: hidden;
                                box-sizing: border-box;
                                margin: 0 0 3px;

                                em {
                                    display: block;
                                    font-size: 130px;
                                    line-height: 1;
                                    color: #e5f4f5;
                                    font-weight: 300;
                                    font-family: 'Lato';
                                    margin: -20px 0 0;
                                    opacity: 0;
                                    transform: translateY(100%);
                                    transition: all .7s .2s;
                                }
                            }

                            h4 {
                                font-size: 30px;
                                line-height: 40px;
                                color: $font1;
                                font-family: 'nanumsquare';
                                margin: 0 0 18px;
                                transform: translateY(50px);
                                opacity: 0;
                                transition: all .4s;

                                b {
                                    font-weight: 700;
                                }
                            }

                            p {
                                font-size: 15px;
                                line-height: 28px;
                                margin: 0 0 13px;
                                transform: translateY(50px);
                                opacity: 0;
                                transition: all .5s .2s;

                                em {
                                    font-size: 22px;
                                    font-weight: 700;
                                    color: $font1;
                                    line-height: 32px;
                                }
                            }

                            ul {
                                padding: 18px 35px;
                                background: #fff;
                                margin: 0 0 14px;
                                box-sizing: border-box;
                                opacity: 0;
                                transition: all .4s;

                                li {
                                    font-size: 15px;
                                    line-height: 28px;
                                    color: $font2;
                                    padding: 0 0 0 8px;
                                    position: relative;

                                    &:before {
                                        display: block;
                                        content: '';
                                        width: 2px;
                                        height: 2px;
                                        background: #222;
                                        border-radius: 50%;
                                        position: absolute;
                                        top: 12px;
                                        left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.pagination {
    text-align: center;
    margin: 80px 0 0;
    
    .inner {
        display: inline-block;
        vertical-align: top;
        overflow: hidden;

        & > a {
            display: block;
            float: left;
            width: 32px;
            height: 32px;
            border: 1px solid #ddd;
            border-radius: 50%;
            box-sizing: border-box;

            i {
                display: block;
                font-size: 12px;
                width: 32px;
                line-height: 32px;
                text-align: center;
                color: #333333;
            }
        }

        .paging {
            display: block;
            float: left;
            padding: 0 10px;

            a {
                display: block;
                font-size: 14px;
                font-weight: 700;
                width: 32px;
                height: 32px;
                line-height: 32px;
                float: left;
                border-radius: 50%;
                font-family: 'nanumsquare';
                
                &.on {
                    background: $main;
                    color: #fff;
                }
            }
        }
    }    
}

.swiper-btn {
    $size: 35px;
    display: block;
    width: $size;
    height: $size;
    text-align: center;
    position: absolute;
    top: 50%;
    margin: -17px 0 0;
    z-index: 10;

    i {
        display: block;
        line-height: $size;
        font-size: $size;
        color: $font1;
    }

    &.prev {
        left: 0;
    }

    &.next {
        right: 0;
    }
}

#pop {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110;

    .inner {
        width: 700px;
        position: absolute;
        top: 15%;
        left: 50%;
        z-index: 10;
        margin: 0 0 0 -350px;
        background: #fff;

        header {
            font-size: 25px;
            line-height: 60px;
            color: #fff;
            background: $main;
            text-align: left;
            padding: 0 30px;
            box-sizing: border-box;
        }

        .cont {
            padding: 30px;
            height: 400px;
            text-align: left;
            overflow-y: scroll;
        }

        .popClose {
            width: 60px;
            height: 60px;
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;

            i {
                display: block;
                font-size: 20px;
                line-height: 60px;
                color: #fff;
            }
        }
    }

    .bg {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: #000;
        opacity: .4;
    }
}