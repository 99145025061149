$red: #d0270d;
$bg-gray: #f5f5f5;
$blue: #0d75bc;
$green: #8cc63e;
$main: #00b7ca;
$font1: #333;
$font2: #666;
$font3: #999;
$border1: #aaa;
$border2: #ddd;

label {
	display: block;
}

input[type="text"], input[type="email"], input[type="password"] {
	display: block;
	width: 100%;
	height: 50px;
	font-size: 14px;
	line-height: 28px;
	padding: 5px;
	border: 1px solid #ddd;
	box-sizing: border-box;
	text-indent: 20px;
}

input[type="text"]::placeholder, input[type="email"]::placeholder, textarea::placeholder {
	font-size: 14px;
}

select {
	display: inline-block;
	vertical-align: top;
	height: 50px;
	padding: 0 20px;
	font-size: 14px;
	color: #999999;
	border: 1px solid #ddd;
}

textarea {
	display: block;
	width: 100%;
	height: 220px;
	border: 1px solid #ddd;
	padding: 20px;
	box-sizing: border-box;
}

.hide {
	display: none;
}

@mixin ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: normal;
	white-space: nowrap;
}

@mixin multi-line($height, $line) {
	display: -webkit-box;
	height: $height;
	-webkit-line-clamp: $line;
	white-space: normal;
	word-break: break-word;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin clear {
	&:after {
		display: block;
		content: '';
		clear: both;
	}
}

@mixin btn-wrap {
	text-align: center;
	overflow: hidden;

	.fl-l {
		float: left;
	}

	.fl-r {
		float: right;
	}

	.btn30 {
		display: inline-block;
		vertical-align: top;
		height: 30px;
		border: 1px solid transparent;
		background: #fff;
		box-sizing: border-box;
		font-size: 13px;
		line-height: 28px;
		text-align: center;
	}

	.btn36 {
		display: inline-block;
		vertical-align: top;
		height: 36px;
		border: 1px solid transparent;
		background: #fff;
		box-sizing: border-box;
		font-size: 13px;
		line-height: 34px;
		text-align: center;
	}

	.btn40 {
		display: inline-block;
		vertical-align: top;
		height: 40px;
		border: 1px solid transparent;
		background: #fff;
		box-sizing: border-box;
		font-size: 13px;
		line-height: 38px;
		text-align: center;

		span, i {
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			line-height: 38px;
		}
	}

	.btn46 {
		display: inline-block;
		vertical-align: top;
		height: 46px;
		border: 1px solid transparent;
		background: #fff;
		box-sizing: border-box;
		font-size: 13px;
		line-height: 34px;
		text-align: center;
	}

	.btn50 {
		display: inline-block;
		vertical-align: top;
		height: 50px;
		border: 1px solid transparent;
		background: #fff;
		box-sizing: border-box;
		font-size: 15px;
		line-height: 48px;
		text-align: center;		
	}

	.btn60 {
		display: inline-block;
		vertical-align: top;
		height: 60px;
		border: 1px solid transparent;
		background: #fff;
		box-sizing: border-box;
		font-size: 15px;
		line-height: 58px;
		text-align: center;
		font-weight: 500;
		font-family: 'notosans';
	}

	.blk {
		background: #222;
		color: #fff;
	}
	
	.blk_bd {
		color: #222;
		border-color: #222;
	}
	
	.blk_bd_bold {
		color: #222;
		border-color: #222;
		border: 2px solid #222;
	}

	.whit {
		background: #fff;
		color: $font2;
		border: 1px solid #fff;
	}

	.blk-bd {
		background: #fff;
		border: 2px solid #454545;
		color: #454545;
	}

	.gry {
		background: #454545;
		color: #fff;
		border: 1px solid #454545;
	}
	
	.gry-bd {
		color: #666;
		color: #222;
		border: 1px solid #dddddd;
	}

	.lt-gray-bd {
		color: #666;
		border-color: #dddddd;

		&.on {
			border-color: #222;
			color: #222;
		}
	}

	.blue {
		background: $blue;
		color: #fff;
	}

	.blue-bd {
		background: #fff;
		color: $blue;
		border: 2px solid $blue;
	}

	.navy {
		background: $main;
		color: #fff;
		border: 1px solid $main;
	}

	.red {
		background: $red;
		color: #fff;
		border: 1px solid $red;
	}
}

.btn-wrap {
	@include btn-wrap;
}

.bar_list {
	display: inline-block;
	vertical-align: top;
	overflow: hidden;

	li {
		padding: 0 9px;
		position: relative;
		float: left;

		&:first-child {
			padding-left: 0;

			&:before {
				display: none;
			}
		}

		&:before {
			display: block;
			content: '';
			width: 1px;
			height: 9px;
			background: #dddddd;
			position: absolute;
			top: 50%;
			left: 0;
			margin: -4px 0 0;
		}

		a {
			display: block;
			color: $font2;
			font-family: 'notosans-dl';

			.cnt {
				display: inline-block;
				vertical-align: top;
				width: 22px;
				height: 22px;
				line-height: 22px;
				background: $green;
				color: #fff;
				border-radius: 50%;
				text-align: center;
				margin: 0 0 0 4px;
			}
		}
	}
}