@import '_init.scss';
#footer {
	border-top: 1px solid #dddddd;

	article {
		width: 1200px;
		margin: 0 auto;
		padding: 40px 0;
		overflow: hidden;

		section {
			float: left;
			padding: 0 0 0 50px;
			box-sizing: border-box;
			border-left: 1px solid #ddd;

			&:first-child {
				border-left: 0;
				padding-left: 0;
			}

			h3 {
				font-size: 16px;
				color: $font2;
				line-height: 27px;
				margin: 0 0 12px;
			}
		}

		.company {
			width: 220px;

			h1 {
				margin: 0 0 12px;
			}

			ul {
				li {
					font-size: 13px;
					line-height: 25px;
					color: #888888;
				}
			}
		}

		.contact {
			width: 367px;

			ul {
				li {
					font-size: 13px;
					line-height: 25px;
					color: #888888;
				}
			}
		}

		.follow {
			width: 270px;

			p {
				font-size: 13px;
				line-height: 18px;
				color: #888888;
				margin: 0 0 8px;
			}

			ul {
				overflow: hidden;

				li {
					width: 46px;
					float: left;
					margin: 0 0 0 15px;
					border-radius: 6px;
					background: #eeeeee;

					&:first-child {
						margin-left: 0;
					}

					a {
						display: block;

						i {
							display: block;
							font-size: 18px;
							line-height: 46px;
							text-align: center;
							color: #bbbbbb;
						}
					}
				}
			}
		}

		.customer {
			width: 340px;

			p {
				font-size: 32px;
				line-height: 1;
				color: #5f554b;
				font-weight: 500;
				margin: 0 0 8px;
			}

			ul {
				li {
					font-size: 13px;
					line-height: 18px;
					color: #888888;
				}
			}
		}
	}

	.copyright {
		width: 1200px;
		margin: 0 auto;
		line-height: 52px;
		font-size: 13px;
		text-align: center;
		color: #aaaaaa;
		border-top: 1px solid #ddd;
	}
}