/*
---------------------------------------------------------------
*
* common
*
---------------------------------------------------------------
*/

@font-face {
	font-family: 'notosans';
	font-weight: 300;
	src: url('../fonts/NotoSansCJKkr-Light.eot');
	src: url('../fonts/NotoSansCJKkr-Light.eot') format('embedded-opentype'), url('../fonts/NotoSansCJKkr-Light.woff') format('woff'), url('../fonts/NotoSansCJKkr-Light.otf') format('truetype');
}

@font-face {
	font-family: 'notosans-dl';
	font-weight: 400;
	src: url('../fonts/NotoSansCJKkr-DemiLight.eot');
	src: url('../fonts/NotoSansCJKkr-DemiLight.eot') format('embedded-opentype'), url('../fonts/NotoSansCJKkr-DemiLight.woff') format('woff'), url('../fonts/NotoSansCJKkr-DemiLight.otf') format('truetype');
}

@font-face {
	font-family: 'notosans';
	font-weight: 400;
	src: url('../fonts/NotoSansCJKkr-Regular.eot');
	src: url('../fonts/NotoSansCJKkr-Regular.eot') format('embedded-opentype'), url('../fonts/NotoSansCJKkr-Regular.woff') format('woff'), url('../fonts/NotoSansCJKkr-Regular.otf') format('truetype');
}

@font-face {
	font-family: 'notosans';
	font-weight: 500;
	src: url('../fonts/NotoSansCJKkr-Medium.eot');
	src: url('../fonts/NotoSansCJKkr-Medium.eot') format('embedded-opentype'), url('../fonts/NotoSansCJKkr-Medium.woff') format('woff'), url('../fonts/NotoSansCJKkr-Medium.otf') format('truetype');
}

@font-face {
	font-family: 'notosans';
	font-weight: 700;
	src: url('../fonts/NotoSansCJKkr-Bold.eot');
	src: url('../fonts/NotoSansCJKkr-Bold.eot') format('embedded-opentype'), url('../fonts/NotoSansCJKkr-Bold.woff') format('woff'), url('../fonts/NotoSansCJKkr-Bold.otf') format('truetype');
}

@font-face {
	font-family: 'nanumsquare';
	font-weight: 400;
	src: url('../fonts/NanumSquareR.eot');
	src: url('../fonts/NanumSquareR.eot') format('embedded-opentype'), url('../fonts/NanumSquareR.woff') format('woff'), url('../fonts/NanumSquareR.otf') format('truetype');
}
