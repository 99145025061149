html, body {
	font-size: 14px;
	font-family: 'Raleway', 'nanumsquare', sans-serif;
	font-weight: 400;
	line-height: 1.5em;
	color: #666;
}

#wrap {
	overflow: hidden;
}

#header, #footer, #wrap {
	width: 100%;
	min-width: 1200px;
	margin: 0 auto;
}

#container {
	width: 100%;
	min-width: 1200px;
	overflow: hidden;
	margin: 85px 0 0;
}

#content {
	width: 1200px;
	margin: 0 auto;
	position: relative;
	padding: 73px 0 130px;
    box-sizing: border-box;

    .cont-img-box {
    	margin: 0 -360px;
    }
}