/*
---------------------------------------------------------------
*
* base
*
---------------------------------------------------------------
*/
@charset "utf-8";
/* CSS Document */


/* css reset */
html, body, div , span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub,
sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, 
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section,
 summary,time, mark, audio, video {
    margin:  0; 
    padding:  0; 
    border:  0;
    line-height: inherit;
 }

 em, i {
    font-style:normal;
 }

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display:  block;
}

ol, ul {
    list-style:  none;
}

table {
    border-collapse:  collapse; 
    border-spacing:  0;
    table-layout: fixed;
}

body,input,textarea,select,button,table{

}
body,div,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,p,
form,fieldset,input,table,tr,th,td{
    margin: 0;
    padding: 0;
}

h1,h2,h3,h4,h5,h6 {
    font-size: 100%; 
    font-weight: normal;
}

strong, b {
    font-weight: 400;
}

em, i {font-style: normal;}

fieldset,img {
    border: 0;
    vertical-align: top;
}

address {
    font-style:normal
}

/* a-style */
a {
    text-decoration:none; color:#333;
}

a:hover,a:active,a:focus {
    text-decoration:none;
}

legend {
    display:none;
}

button {
    border:0;
    padding:0;
    background:transparent;
}

input[type="text"], input[type="password"] {
    display: block;
    padding: 5px;
    line-height: 28px;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
}
input[type="text"]::placeholder, input[type="password"]::placeholder {
    font-size: 12px;
    color: #999999;
    font-family: 'notosans-dl';    
}

textarea {                                    
    border: 1px solid #dfdfdf;
    background: #fff;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;

    &::placeholder {
        font-size: 12px;
        color: #999999;
        font-family: 'notosans-dl';
    }
}

:focus {
    //outline: none;
}

::selection {
    background: #0095ba;
    color: #fff
}