@import '_init.scss';
#header {
	width: 100%;
	height: 85px;
	border-bottom: 1px solid #ddd;
	box-sizing: border-box;
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	&:before {
		display: block;
		content: '';
		width: 100%;
		height: 50px;
		background: #fff;
		position: absolute;
		top: 85px;
		left: 0;
		transform: translateY(-100%);
		transition: all .5s;
	}

	&.on {
		&:before {
			transform: translateY(0);
		}
	}

	.inner {
		width: 1200px;
		margin: 0 auto;
		position: relative;
		z-index: 10;
		padding: 0 0 0 275px;
		@include clear;

		.logo {
			position: absolute;
			top: 14px;
			left: 0;

			a {
				display: block;
			}
		}

		#gnb {
			float: left;

			& > ul {
				@include clear;

				& > li {
					display: table;
					width: 100px;
					float: left;
					table-layout: fixed;
					position: relative;
					margin: 0 0 0 10px;

					&:hover {
						& > a {
							color: $main;

							&:before {
								transform: scaleX(1);
							}
						}
					}

					&:first-child {
						margin-left: 0;
					}

					& > a {
						display: table-cell;
						vertical-align: middle;
						width: 100%;
						height: 85px;
						font-size: 15px;
						font-weight: 700;
						text-align: center;
						color: #454545;
						line-height: 1.5;
						position: relative;

						&:before {
							display: block;
							content: '';
							width: 100%;
							height: 3px;
							background: $main;
							position: absolute;
							bottom: 0;
							left: 0;
							transform: scaleX(0);
							transform-origin: left;
							transition: all .4s;
						}
					}

					.depth2 {
						display: none;
						min-width: 500px;
						position: absolute;
						top: 85px;
						left: 50%;
						margin: 0 0 0 -250px;
						overflow: hidden;
						text-align: center;

						li {
							display: inline-block;
							vertical-align: top;
							margin: 0 0 0 30px;

							&:first-child {
								margin-left: 0;
							}

							a {
								display: block;
								color: #747474;
								line-height: 50px;
								transition: all .5s;

								&:hover {
									color: $main;
								}
							}
						}
					}
				}
			}
		}

		.sns {
			padding: 35px 0;
			float: right;

			ul {
				overflow: hidden;

				li {
					float: left;
					margin: 0 0 0 25px;

					&:first-child {
						margin-left: 0;
					}

					a {
						display: block;

						&:hover {
							i {
								&.xi-blogger {color: #00d137;}
								&.xi-instagram {color: #ff286d;}
								&.xi-facebook {color: #3c5a9a;}
							}
						}

						i {
							display: block;
							font-size: 20px;
							color: #666666;
							transition: color .5s;
						}
					}
				}
			}
		}
	}
}